@import "fonts";
@import "variables";

// Core variables and mixins
@import "bootstrap/bootstrap/variables";
@import "bootstrap/bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/bootstrap/normalize";
@import "bootstrap/bootstrap/print";

// Core CSS
@import "bootstrap/bootstrap/scaffolding";
@import "bootstrap/bootstrap/type";
@import "bootstrap/bootstrap/code";
@import "bootstrap/bootstrap/grid";

// Components
@import "bootstrap/bootstrap/pager";

@import "highlight";

body { font-feature-settings: "liga", "locl"; }

body > header {
    div.title {
        @extend h1;
        font-family: 'Tulpen One', sans-serif;
        font-size: 600%;
        a, a:hover, a:active, a:visited {
            color: inherit;
            text-decoration: inherit;
        }
    }

    div.subtitle {
        @extend h2;
        font-family: $font-family-sans-serif;
        font-size: 100%;
    }

    margin-bottom: 50px;
}

@media print {
    body > header {
        display: none !important;
    }
}

main {
    text-align: justify;
    text-shadow: 1px 1px rgba(255,255,255,0.8);
}

article {
    h1 { a, a:hover, a:active, a:visited { color: inherit; } }

    p {
        -webkit-hyphens: auto;
           -moz-hyphens: auto;
            -ms-hyphens: auto;
                hyphens: auto;
    }

    img {
        @extend .img-thumbnail;
        @extend .img-responsive;
    }

    div.meta {
        @extend small;
        @extend p;
        font-family: $font-family-sans-serif;
        padding-left: 3px;

        margin-bottom: 30px;
        address {
            display: inline;
        }
    }
}

.title {
    @extend .lead;
    font-family: $font-family-sans-serif;
}

.pager, body > footer {
    font-family: $font-family-sans-serif;
    @extend small;
}

.articles article+article:before, body>footer {
    content: "";
    display: block;
    height: 1px;
    @extend hr;
}

article.old {
    font-family: Lora, Georgia, "Times New Roman", Times, serif !important;
    font-feature-settings: "locl" 0 !important;
}
