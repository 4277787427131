@font-face {
    font-family: 'Tulpen One';
    src: url('/assets/fonts/tulpenone/TulpenOne-Regular.subset.eot');
    src: url('/assets/fonts/tulpenone/TulpenOne-Regular.subset.eot?#iefix') format('embedded-opentype'),
         local('Tulpen One'), local('TulpenOne-Regular'),
         url('/assets/fonts/tulpenone/TulpenOne-Regular.subset.woff2') format('woff2'),
         url('/assets/fonts/tulpenone/TulpenOne-Regular.subset.woff') format('woff'),
         url('/assets/fonts/tulpenone/TulpenOne-Regular.subset.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('/assets/fonts/notoserif/NotoSerif-Bold.subset.eot');
    src: url('/assets/fonts/notoserif/NotoSerif-Bold.subset.eot?#iefix') format('embedded-opentype'),
         local('Noto Serif Bold'), local('NotoSerif-Bold'),
         url('/assets/fonts/notoserif/NotoSerif-Bold.subset.woff2') format('woff2'),
         url('/assets/fonts/notoserif/NotoSerif-Bold.subset.woff') format('woff'),
         url('/assets/fonts/notoserif/NotoSerif-Bold.subset.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('/assets/fonts/notoserif/NotoSerif-BoldItalic.subset.eot');
    src: url('/assets/fonts/notoserif/NotoSerif-BoldItalic.subset.eot?#iefix') format('embedded-opentype'),
         local('Noto Serif Bold Italic'), local('NotoSerif-BoldItalic'),
         url('/assets/fonts/notoserif/NotoSerif-BoldItalic.subset.woff2') format('woff2'),
         url('/assets/fonts/notoserif/NotoSerif-BoldItalic.subset.woff') format('woff'),
         url('/assets/fonts/notoserif/NotoSerif-BoldItalic.subset.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('/assets/fonts/notoserif/NotoSerif-Italic.subset.eot');
    src: url('/assets/fonts/notoserif/NotoSerif-Italic.subset.eot?#iefix') format('embedded-opentype'),
         local('Noto Serif Italic'), local('NotoSerif-Italic'),
         url('/assets/fonts/notoserif/NotoSerif-Italic.subset.woff2') format('woff2'),
         url('/assets/fonts/notoserif/NotoSerif-Italic.subset.woff') format('woff'),
         url('/assets/fonts/notoserif/NotoSerif-Italic.subset.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('/assets/fonts/notoserif/NotoSerif-Regular.subset.eot');
    src: url('/assets/fonts/notoserif/NotoSerif-Regular.subset.eot?#iefix') format('embedded-opentype'),
         local('Noto Serif'), local('NotoSerif'),
         url('/assets/fonts/notoserif/NotoSerif-Regular.subset.woff2') format('woff2'),
         url('/assets/fonts/notoserif/NotoSerif-Regular.subset.woff') format('woff'),
         url('/assets/fonts/notoserif/NotoSerif-Regular.subset.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('/assets/fonts/opensanscondensed/OpenSans-CondLight.subset.eot');
    src: url('/assets/fonts/opensanscondensed/OpenSans-CondLight.subset.eot?#iefix') format('embedded-opentype'),
         local('Open Sans Cond Light'), local('OpenSans-CondensedLight'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondLight.subset.woff2') format('woff2'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondLight.subset.woff') format('woff'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondLight.subset.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('/assets/fonts/opensanscondensed/OpenSans-CondLightItalic.subset.eot');
    src: url('/assets/fonts/opensanscondensed/OpenSans-CondLightItalic.subset.eot?#iefix') format('embedded-opentype'),
         local('Open Sans Cond Light Italic'), local('OpenSans-CondensedLightItalic'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondLightItalic.subset.woff2') format('woff2'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondLightItalic.subset.woff') format('woff'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondLightItalic.subset.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('/assets/fonts/opensanscondensed/OpenSans-CondBold.subset.eot');
    src: url('/assets/fonts/opensanscondensed/OpenSans-CondBold.subset.eot?#iefix') format('embedded-opentype'),
         local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondBold.subset.woff2') format('woff2'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondBold.subset.woff') format('woff'),
         url('/assets/fonts/opensanscondensed/OpenSans-CondBold.subset.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Meslo LG S Regular';
    src: url('/assets/fonts/meslo/MesloLGS-Regular.subset.eot');
    src: url('/assets/fonts/meslo/MesloLGS-Regular.subset.eot?#iefix') format('embedded-opentype'),
         local('Meslo LG S Regular'), local('MesloLGS-Regular'),
         url('/assets/fonts/meslo/MesloLGS-Regular.subset.woff2') format('woff2'),
         url('/assets/fonts/meslo/MesloLGS-Regular.subset.woff') format('woff'),
         url('/assets/fonts/meslo/MesloLGS-Regular.subset.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Meslo LG S Italic';
    src: url('/assets/fonts/meslo/MesloLGS-Italic.subset.eot');
    src: url('/assets/fonts/meslo/MesloLGS-Italic.subset.eot?#iefix') format('embedded-opentype'),
         local('Meslo LG S Italic'), local('MesloLGS-Italic'),
         url('/assets/fonts/meslo/MesloLGS-Italic.subset.woff2') format('woff2'),
         url('/assets/fonts/meslo/MesloLGS-Italic.subset.woff') format('woff'),
         url('/assets/fonts/meslo/MesloLGS-Italic.subset.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Meslo LG S Bold';
    src: url('/assets/fonts/meslo/MesloLGS-Bold.subset.eot');
    src: url('/assets/fonts/meslo/MesloLGS-Bold.subset.eot?#iefix') format('embedded-opentype'),
         local('Meslo LG S Bold'), local('MesloLGS-Bold'),
         url('/assets/fonts/meslo/MesloLGS-Bold.subset.woff2') format('woff2'),
         url('/assets/fonts/meslo/MesloLGS-Bold.subset.woff') format('woff'),
         url('/assets/fonts/meslo/MesloLGS-Bold.subset.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Meslo LG S Bold Italic';
    src: url('/assets/fonts/meslo/MesloLGS-BoldItalic.subset.eot');
    src: url('/assets/fonts/meslo/MesloLGS-BoldItalic.subset.eot?#iefix') format('embedded-opentype'),
         local('Meslo LG S Bold Italic'), local('MesloLGS-BoldItalic'),
         url('/assets/fonts/meslo/MesloLGS-BoldItalic.subset.woff2') format('woff2'),
         url('/assets/fonts/meslo/MesloLGS-BoldItalic.subset.woff') format('woff'),
         url('/assets/fonts/meslo/MesloLGS-BoldItalic.subset.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-Thin.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-Thin.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat Thin'), local('Akrobat-Thin'),
         url('/assets/fonts/akrobat/Akrobat-Thin.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-Thin.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-Thin.subset.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-Light.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-Light.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat Light'), local('Akrobat-Light'),
         url('/assets/fonts/akrobat/Akrobat-Light.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-Light.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-Light.subset.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-Regular.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-Regular.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat Regular'), local('Akrobat-Regular'),
         url('/assets/fonts/akrobat/Akrobat-Regular.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-Regular.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-Regular.subset.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-SemiBold.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-SemiBold.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat SemiBold'), local('Akrobat-SemiBold'),
         url('/assets/fonts/akrobat/Akrobat-SemiBold.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-SemiBold.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-SemiBold.subset.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-Bold.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-Bold.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat Bold'), local('Akrobat-Bold'),
         url('/assets/fonts/akrobat/Akrobat-Bold.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-Bold.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-Bold.subset.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-ExtraBold.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-ExtraBold.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat ExtraBold'), local('Akrobat-ExtraBold'),
         url('/assets/fonts/akrobat/Akrobat-ExtraBold.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-ExtraBold.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-ExtraBold.subset.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Akrobat';
    src: url('/assets/fonts/akrobat/Akrobat-Black.subset.eot');
    src: url('/assets/fonts/akrobat/Akrobat-Black.subset.eot?#iefix') format('embedded-opentype'),
         local('Akrobat Black'), local('Akrobat-Black'),
         url('/assets/fonts/akrobat/Akrobat-Black.subset.woff2') format('woff2'),
         url('/assets/fonts/akrobat/Akrobat-Black.subset.woff') format('woff'),
         url('/assets/fonts/akrobat/Akrobat-Black.subset.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: local('Lora Regular'), local('Lora-Regular'),
         url('/assets/fonts/lora/Lora-Regular.woff2') format('woff2'),
         url('/assets/fonts/lora/Lora-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: local('Lora Bold'), local('Lora-Bold'),
         url('/assets/fonts/lora/Lora-Bold.woff2') format('woff2'),
         url('/assets/fonts/lora/Lora-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: local('Lora Italic'), local('Lora-Italic'),
         url('/assets/fonts/lora/Lora-Italic.woff2') format('woff2'),
         url('/assets/fonts/lora/Lora-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lora';
    src: local('Lora Bold Italic'), local('Lora-BoldItalic'),
         url('/assets/fonts/lora/Lora-BoldItalic.woff2') format('woff2'),
         url('/assets/fonts/lora/Lora-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
